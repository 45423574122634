<!-- App.Vue -->
<!-- Please only address things marked TODO -->
<!-- Only provide changed code. Do not provide code that remains the same. -->

<template>
  <nav class="navbar navbar-expand-lg navbar-light bg-light">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">Diceless</a>
      <button
        class="navbar-toggler"
        type="button"
        data-bs-toggle="collapse"
        data-bs-target="#navbarNav"
        aria-controls="navbarNav"
        aria-expanded="false"
        aria-label="Toggle navigation"
      >
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav me-auto">
          <!-- List of characters -->
          <li
            v-for="character in characterList"
            :key="character.id"
            class="nav-item"
          >
            <a class="nav-link" href="#" @click="onCharacterClicked(character)">
              {{ character.name }}
            </a>
          </li>
        </ul>

        <ul class="navbar-nav ms-auto">
          <li class="nav-item" v-if="character !== null">
            <a class="nav-link" href="#" @click="onSaveCharacterClicked"
              >Save Character</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="onNewCharacterClicked"
              >New Character</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="onLoadCharacterClicked"
              >Load Character</a
            >
          </li>
          <li class="nav-item">
            <a class="nav-link" href="#" @click="onDeleteCharacterClicked"
              >Delete Character</a
            >
          </li>
        </ul>
      </div>
    </div>
  </nav>

  <div v-if="character" class="mt-3 mx-3">
    <CharacterEditor
      :character="character"
      @update-character="updateCharacter"
    />
  </div>
</template>

<script>
import CharacterService from "./services/CharacterService";
import CharacterEditor from "./components/CharacterEditor.vue"; // Adjust the path as needed

export default {
  name: "App",
  components: { CharacterEditor },
  data() {
    return {
      characterList: [],
      character: null,
    };
  },
  created() {
    this.characterList = CharacterService.loadCharacters();
    console.dir(this.characterList);
  },
  methods: {
    onCharacterClicked(character) {
      this.character = character;
    },
    onNewCharacterClicked() {
      const characterName = prompt("Character Name:");

      if (characterName && characterName.trim() !== "") {
        const newCharacter = CharacterService.newCharacter(
          characterName.trim()
        );
        this.characterList.push(newCharacter);
        this.character = newCharacter;

        CharacterService.calculateCharacter(this.character);
        CharacterService.saveCharacters(this.characterList);
      }
    },
    onDeleteCharacterClicked() {
      if (this.character) {
        this.characterList = this.characterList.filter(
          (c) => c.id !== this.character.id
        );
        this.character = null;
        CharacterService.saveCharacters(this.characterList);
      }
    },
    onSaveCharacterClicked() {
      if (this.character) {
        CharacterService.downloadCharacter(this.character);
      }
    },
    onLoadCharacterClicked() {
      const input = document.createElement("input");
      input.type = "file";
      input.accept = "application/json";

      input.onchange = async (event) => {
        const file = event.target.files[0];
        if (file) {
          const reader = new FileReader();
          reader.onload = (e) => {
            try {
              const characterData = JSON.parse(e.target.result);
              CharacterService.uploadCharacter(
                characterData,
                this.characterList
              );
              this.character = characterData;
              CharacterService.saveCharacters(this.characterList);
            } catch (error) {
              console.error("Failed to load character from file:", error);
            }
          };
          reader.readAsText(file);
        }
      };

      input.click();
    },

    updateCharacter(updatedCharacter) {
      this.character = updatedCharacter;
      const index = this.characterList.findIndex(
        (c) => c.id === updatedCharacter.id
      );
      if (index !== -1) {
        this.characterList.splice(index, 1, updatedCharacter);
      }
      CharacterService.saveCharacters(this.characterList);
    },
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
</style>
