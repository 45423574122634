<!-- CharacterEditor.Vue -->
<!-- Please only address things marked TODO -->
<!-- Only provide changed code. Do not provide code that remains the same. -->

<template>
  <div class="row">
    <div class="col-12">
      <div class="progress">
        <!-- add the localCharacter.calculated.expBarColor class to this bar -->
        <!-- add the localCharacter.calculated.expBarColor class to this bar -->
        <div
          :class="['progress-bar', localCharacter.calculated.expBarColor]"
          role="progressbar"
          :style="{ width: localCharacter.calculated.expBarDisplay + '%' }"
          :aria-valuenow="localCharacter.calculated.expBarDisplay"
          aria-valuemin="0"
          aria-valuemax="100"
        >
          {{ localCharacter.calculated.usedPoints }} /
          {{ localCharacter.calculated.maxPoints }}
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Affinity</label>
        <input
          class="form-control"
          v-model="localCharacter.core.affinity"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Agility</label>
        <input
          class="form-control"
          v-model="localCharacter.core.agility"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Awareness</label>
        <input
          class="form-control"
          v-model="localCharacter.core.awareness"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Intelligence</label>
        <input
          class="form-control"
          v-model="localCharacter.core.intelligence"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Precision</label>
        <input
          class="form-control"
          v-model="localCharacter.core.precision"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Strength</label>
        <input
          class="form-control"
          v-model="localCharacter.core.strength"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Willpower</label>
        <input
          class="form-control"
          v-model="localCharacter.core.willpower"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1 offset-xl-1">
        <label class="control-label">Gold</label>
        <input
          class="form-control"
          v-model="localCharacter.calculated.gold"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1 ">
        <label class="control-label">Current EXP</label>
        <input
          class="form-control"
          v-model="localCharacter.calculated.currentExp"
          @input="updateCharacter"
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Growth Rate</label>
        <input
          class="form-control"
          v-model="localCharacter.calculated.expRate"
          @input="updateCharacter"
          disabled
          type="number"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-1">
        <label class="control-label">Level</label>
        <input
          class="form-control"
          v-model="localCharacter.calculated.level"
          @input="updateCharacter"
          type="number"
        />
      </div>
    </div>
    <div class="row">
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <label class="control-label">Focus</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.focusBonus -= 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canDecreaseCost"
            >
              -
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="localCharacter.calculated.focusMax"
            disabled
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.focusBonus += 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canIncreaseCost"
            >
              +
            </button>
          </div>
        </div>
        <label class="control-label">Current</label>
        <input
          type="text"
          class="form-control"
          v-model="localCharacter.calculated.focus"
          @change="updateCharacter()"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <label class="control-label">Spirit</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.spiritBonus -= 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canDecreaseCost"
            >
              -
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="localCharacter.calculated.spiritMax"
            disabled
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.spiritBonus += 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canIncreaseCost"
            >
              +
            </button>
          </div>
        </div>
        <label class="control-label">Current</label>
        <input
          type="number"
          class="form-control"
          v-model="localCharacter.calculated.spirit"
          @change="updateCharacter()"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <label class="control-label">Stamina</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.staminaBonus -= 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canDecreaseCost"
            >
              -
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="localCharacter.calculated.staminaMax"
            disabled
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.staminaBonus += 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canIncreaseCost"
            >
              +
            </button>
          </div>
        </div>
        <label class="control-label">Current</label>
        <input
          type="number"
          class="form-control"
          v-model="localCharacter.calculated.stamina"
          @change="updateCharacter()"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <label class="control-label">Temperature</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button class="btn btn-outline-secondary" type="button" disabled>
              -
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="localCharacter.calculated.temperatureMax"
            disabled
          />
          <div class="input-group-append">
            <button class="btn btn-outline-secondary" type="button" disabled>
              +
            </button>
          </div>
        </div>
        <label class="control-label">Current</label>
        <input
          type="number"
          class="form-control"
          v-model="localCharacter.calculated.temperature"
          @change="updateCharacter()"
        />
      </div>

      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <label class="control-label">Vitality</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.vitalityBonus -= 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canDecreaseCost"
            >
              -
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="localCharacter.calculated.vitalityMax"
            disabled
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.vitalityBonus += 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canIncreaseCost"
            >
              +
            </button>
          </div>
        </div>
        <label class="control-label">Current</label>
        <input
          type="number"
          class="form-control"
          v-model="localCharacter.calculated.vitality"
          @change="updateCharacter()"
        />
      </div>
      <div class="col-12 col-md-6 col-lg-4 col-xl-2">
        <label class="control-label">Spell Capacity</label>
        <div class="input-group">
          <div class="input-group-prepend">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.spellCapacityBonus -= 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canDecreaseCost"
            >
              -
            </button>
          </div>
          <input
            type="text"
            class="form-control"
            v-model="localCharacter.calculated.spellCapacityMax"
            disabled
          />
          <div class="input-group-append">
            <button
              class="btn btn-outline-secondary"
              type="button"
              @click="
                localCharacter.calculated.spellCapacityBonus += 1;
                updateCharacter();
              "
              :disabled="!localCharacter.calculated.canIncreaseCost"
            >
              +
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-lg-4">
        <div class="card">
          <div class="card-header">Binding</div>
          <div class="card-body">
            <div class="row">
              <div class="col-3"></div>
              <div class="col-3">
                <label class="control-label">Poor</label>
                <input
                  class="form-control"
                  v-model="localCharacter.calculated.basePoorBindingEfficiency"
                  disabled
                />
              </div>
              <div class="col-3">
                <label class="control-label">Good</label>
                <input
                  class="form-control"
                  v-model="localCharacter.calculated.baseGoodBindingEfficiency"
                  disabled
                />
              </div>
              <div class="col-3">
                <label class="control-label">True</label>
                <input
                  class="form-control"
                  v-model="localCharacter.calculated.baseTrueBindingEfficiency"
                  disabled
                />
              </div>
            </div>
            <!-- Biochemical Binding -->
            <div class="row mt-2">
              <div class="col-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="
                      localCharacter.calculated.biochemicalBindingTrained
                    "
                    id="biochemicalBindingTrained"
                    v-on:change="updateCharacter()"
                  />
                  <label
                    class="form-check-label"
                    for="biochemicalBindingTrained"
                  >
                    Biochemical
                  </label>
                </div>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.biochemicalPoorBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.biochemicalGoodBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="
                      localCharacter.calculated.biochemicalTrueBindingEfficiency
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.biochemicalBindingBonus -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.biochemicalBindingBonus += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Electromagnetic Binding -->
            <div class="row mt-2">
              <div class="col-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="
                      localCharacter.calculated.electromagneticBindingTrained
                    "
                    id="electromagneticBindingTrained"
                    v-on:change="updateCharacter()"
                  />
                  <label
                    class="form-check-label"
                    for="electromagneticBindingTrained"
                  >
                    Electromagnetic
                  </label>
                </div>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated
                      .electromagneticPoorBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated
                      .electromagneticGoodBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="
                      localCharacter.calculated
                        .electromagneticTrueBindingEfficiency
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.electromagneticBindingBonus -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.electromagneticBindingBonus += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Kinetic Binding -->
            <div class="row mt-2">
              <div class="col-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="localCharacter.calculated.kineticBindingTrained"
                    id="kineticBindingTrained"
                    v-on:change="updateCharacter()"
                  />
                  <label class="form-check-label" for="kineticBindingTrained">
                    Kinetic
                  </label>
                </div>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.kineticPoorBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.kineticGoodBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="
                      localCharacter.calculated.kineticTrueBindingEfficiency
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.kineticBindingBonus -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.kineticBindingBonus += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Photonic Binding -->
            <div class="row mt-2">
              <div class="col-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="localCharacter.calculated.photonicBindingTrained"
                    id="photonicBindingTrained"
                    v-on:change="updateCharacter()"
                  />
                  <label class="form-check-label" for="photonicBindingTrained">
                    Photonic
                  </label>
                </div>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.photonicPoorBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.photonicGoodBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="
                      localCharacter.calculated.photonicTrueBindingEfficiency
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.photonicBindingBonus -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.photonicBindingBonus += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Sonic Binding -->
            <div class="row mt-2">
              <div class="col-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="localCharacter.calculated.sonicBindingTrained"
                    id="sonicBindingTrained"
                    v-on:change="updateCharacter()"
                  />
                  <label class="form-check-label" for="sonicBindingTrained">
                    Sonic
                  </label>
                </div>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="localCharacter.calculated.sonicPoorBindingEfficiency"
                  disabled
                />
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="localCharacter.calculated.sonicGoodBindingEfficiency"
                  disabled
                />
              </div>
              <div class="col-3">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="
                      localCharacter.calculated.sonicTrueBindingEfficiency
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.sonicBindingBonus -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.sonicBindingBonus += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <!-- Thermal Binding -->
            <div class="row mt-2">
              <div class="col-3">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    v-model="localCharacter.calculated.thermalBindingTrained"
                    id="thermalBindingTrained"
                    v-on:change="updateCharacter()"
                  />
                  <label class="form-check-label" for="thermalBindingTrained">
                    Thermal
                  </label>
                </div>
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.thermalPoorBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <input
                  class="form-control"
                  v-model="
                    localCharacter.calculated.thermalGoodBindingEfficiency
                  "
                  disabled
                />
              </div>
              <div class="col-3">
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="
                      localCharacter.calculated.thermalTrueBindingEfficiency
                    "
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.thermalBindingBonus -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.thermalBindingBonus += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-6">
        <div class="card">
          <div class="card-header">Spellcasting</div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <label class="control-label"> Biochemical </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.biochemicalAffinity"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusBiochemicalAffinity -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusBiochemicalAffinity += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.healingTraining"
                      id="healingTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="healingTraining">
                      Healing
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.healingSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusHealingSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusHealingSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.poisonTraining"
                      id="poisonTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="poisonTraining">
                      Poison
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.poisonSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusPoisonSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusPoisonSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.acidicTraining"
                      id="acidicTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="acidicTraining">
                      Acidic
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.acidicSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusAcidicSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusAcidicSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Water Specialty -->
              <div class="col-2">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.waterTraining"
                      id="waterTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="waterTraining">
                      Water
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.waterSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusWaterSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusWaterSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <label class="control-label"> Electromagnetic </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.electromagneticAffinity"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusElectromagneticAffinity -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusElectromagneticAffinity += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!-- Electric Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.electricTraining"
                      id="electricTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="electricTraining">
                      Electric
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.electricSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusElectricSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusElectricSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Magnetic Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.magneticTraining"
                      id="magneticTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="magneticTraining">
                      Magnetic
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.magneticSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusMagneticSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusMagneticSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label class="control-label"> Kinetic </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.kineticAffinity"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusKineticAffinity -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusKineticAffinity += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Force Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.forceTraining"
                      id="forceTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="forceTraining">
                      Force
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.forceSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusForceSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusForceSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Gravitational Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.gravitationalTraining"
                      id="gravitationalTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="gravitationalTraining">
                      Gravitational
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.gravitationalSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusGravitationalSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusGravitationalSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label class="control-label"> Photonic </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.photonicAffinity"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusPhotonicAffinity -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusPhotonicAffinity += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Light Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.lightTraining"
                      id="lightTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="lightTraining">
                      Light
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.lightSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusLightSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusLightSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Dark Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.darkTraining"
                      id="darkTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="darkTraining">
                      Dark
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.darkSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusDarkSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusDarkSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label class="control-label"> Sonic </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.sonicAffinity"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusSonicAffinity -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusSonicAffinity += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Communication Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.communicationTraining"
                      id="communicationTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="communicationTraining">
                      Communication
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.communicationSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusCommunicationSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusCommunicationSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Vibration Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.vibrationTraining"
                      id="vibrationTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="vibrationTraining">
                      Vibration
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.vibrationSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusVibrationSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusVibrationSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-4">
                <label class="control-label"> Thermal </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.thermalAffinity"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusThermalAffinity -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusThermalAffinity += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!-- Fire Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.fireTraining"
                      id="fireTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="fireTraining">
                      Fire
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.fireSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusFireSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusFireSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Ice Specialty -->
              <div class="col-4">
                <label class="control-label">
                  <div class="form-check">
                    <input
                      class="form-check-input"
                      type="checkbox"
                      v-model="localCharacter.calculated.iceTraining"
                      id="iceTraining"
                      v-on:change="updateCharacter()"
                    />
                    <label class="form-check-label" for="iceTraining">
                      Ice
                    </label>
                  </div>
                </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.iceSpecialty"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusIceSpecialty -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusIceSpecialty += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-2">
        <div class="card">
          <div class="card-header">Skills</div>
          <div class="card-body">
            <div class="row">
              <!-- Melee Accuracy -->
              <div class="col-6">
                <label class="control-label"> Melee Acc </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.meleeAccuracy"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusMeleeAccuracy -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusMeleeAccuracy += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>

              <!-- Melee Dodge -->
              <div class="col-6">
                <label class="control-label"> Melee Dodge </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.meleeDodge"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusMeleeDodge -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusMeleeDodge += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!-- Ranged Accuracy -->
              <div class="col-6">
                <label class="control-label"> Ranged Acc </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.rangedAccuracy"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusRangedAccuracy -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusRangedAccuracy += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!-- Ranged Dodge -->
              <div class="col-6">
                <label class="control-label"> Ranged Dodge </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.rangedDodge"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusRangedDodge -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.bonusRangedDodge += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!-- Perception -->
              <div class="col-6">
                <label class="control-label"> Perception </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.perception"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.BonusPerception -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.BonusPerception += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
              <!-- Stealth -->
              <div class="col-6">
                <label class="control-label"> Stealth </label>
                <div class="input-group">
                  <input
                    class="form-control"
                    type="text"
                    v-model="localCharacter.calculated.stealth"
                    disabled
                  />
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.BonusStealth -= 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canDecreaseCost"
                    >
                      -
                    </button>
                  </div>
                  <div class="input-group-append">
                    <button
                      class="btn btn-outline-secondary"
                      type="button"
                      @click="
                        localCharacter.calculated.BonusStealth += 1;
                        updateCharacter();
                      "
                      :disabled="!localCharacter.calculated.canIncreaseCost"
                    >
                      +
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12 col-lg-4">
        <!-- Resistances -->
        <div class="card">
          <div class="card-header">Damage Reduction</div>
          <div class="card-body">
            <div class="row">
              <div class="col-4">
                <label class="control-label">Bludgeoning</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bludgeoningDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated
                          .bonusBludgeoningDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Slashing</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.slashingDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusSlashingDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Piercing</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.piercingDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusPiercingDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Healing</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.healingDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusHealingDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Poison</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.poisonDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusPoisonDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Acidic</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.acidicDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusAcidicDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Water</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.waterDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusWaterDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Electric</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.electricDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusElectricDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Magnetic</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.magneticDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusMagneticDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Force</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.forceDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusForceDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Gravitational</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.gravitationalDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated
                          .bonusGravitationalDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Light</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.lightDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusLightDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Dark</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.darkDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusDarkDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Communication</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.communicationDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated
                          .bonusCommunicationDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Vibration</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.vibrationDamageReduction
                      "
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusVibrationDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Fire</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.fireDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusFireDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-4">
                <label class="control-label">Ice</label>
                <div class="row">
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="localCharacter.calculated.iceDamageReduction"
                      disabled
                    />
                  </div>
                  <div class="col-6">
                    <input
                      class="form-control"
                      v-model="
                        localCharacter.calculated.bonusIceDamageReduction
                      "
                      type="number"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <!-- Spells -->
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <span
              >Spells ({{ character.spells.length }}/{{
                character.calculated.spellCapacityMax
              }})</span
            >
            <button
              class="btn btn-outline-secondary"
              @click="onNewSpellClicked()"
            >
              +
            </button>
          </div>
          <div class="card-body">
            <div
              class="row"
              v-for="(spell, index) in localCharacter.spells"
              :key="index"
            >
              <div class="col-4">
                <label class="control-label">Name</label>
                <input
                  class="form-control"
                  v-model="spell.name"
                  @blur="updateCharacter()"
                />
              </div>
              <div class="col-3">
                <label class="control-label">Type</label>
                <select
                  class="form-select"
                  v-model="spell.type"
                  @change="updateCharacter()"
                >
                  <option value="healing">Healing</option>
                  <option value="poison">Poison</option>
                  <option value="acidic">Acidic</option>
                  <option value="water">Water</option>
                  <option value="electric">Electric</option>
                  <option value="magnetic">Magnetic</option>
                  <option value="force">Force</option>
                  <option value="gravitational">Gravitational</option>
                  <option value="light">Light</option>
                  <option value="dark">Dark</option>
                  <option value="communication">Communication</option>
                  <option value="vibration">Vibration</option>
                  <option value="fire">Fire</option>
                  <option value="ice">Ice</option>
                </select>
              </div>

              <div class="col-5">
                <label class="control-label">Style</label>
                <select
                  class="form-select"
                  v-model="spell.style"
                  @change="updateCharacter()"
                >
                  <option value="touch">Touch (100%)</option>
                  <option value="target">Target (-5/m)</option>
                  <option value="aoe">AOE (50%)</option>
                </select>
              </div>

              <div class="col-10">
                <label class="control-label">Description</label>
                <textarea
                  class="form-control"
                  v-model="spell.description"
                  @blur="updateCharacter()"
                ></textarea>
              </div>

              <div class="col-2 d-flex align-items-end justify-content-end">
                <button
                  class="btn btn-outline-secondary"
                  @click="onDeleteSpellClicked(index)"
                >
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-lg-4">
        <!-- Attacks -->
        <div class="card">
          <div
            class="card-header d-flex justify-content-between align-items-center"
          >
            <span>Attacks</span>
            <button
              class="btn btn-outline-secondary"
              @click="onNewAttackClicked()"
            >
              +
            </button>
          </div>
          <div class="card-body">
            <div
              class="row"
              v-for="(attack, index) in localCharacter.attacks"
              :key="index"
            >
              <div class="col-3">
                <label class="control-label">Name</label>
                <input
                  class="form-control"
                  v-model="attack.name"
                  @blur="updateCharacter()"
                />
              </div>
              <div class="col-3">
                <label class="control-label">Type</label>
                <select
                  class="form-select"
                  v-model="attack.type"
                  @change="updateCharacter()"
                >
                  <option value="melee">Melee</option>
                  <option value="ranged">Ranged</option>
                </select>
              </div>

              <div class="col-12">
                <div class="row" v-if="attack.type == 'melee'">
                  <div class="col-3">
                    <label class="control-label">Accuracy</label>
                    <input
                      class="form-control"
                      type="number"
                      :value="
                        attack.accuracyBonus +
                        localCharacter.calculated.meleeAccuracy
                      "
                      disabled
                    />
                  </div>
                  <div class="col-3">
                    <label class="control-label">Acc +</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="attack.accuracyBonus"
                      @change="updateCharacter()"
                    />
                  </div>
                  <div class="col-3">
                    <label class="control-label">Damage</label>
                    <input
                      class="form-control"
                      type="number"
                      :value="
                        attack.damageBonus +
                        localCharacter.calculated.meleeDamage
                      "
                      disabled
                    />
                  </div>
                  <div class="col-3">
                    <label class="control-label">Dmg +</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="attack.damageBonus"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
                <div class="row" v-if="attack.type == 'ranged'">
                  <div class="col-3">
                    <label class="control-label">Accuracy</label>
                    <input
                      class="form-control"
                      type="number"
                      :value="
                        attack.accuracyBonus +
                        localCharacter.calculated.rangedAccuracy
                      "
                      disabled
                    />
                  </div>
                  <div class="col-3">
                    <label class="control-label">Acc +</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="attack.accuracyBonus"
                      @change="updateCharacter()"
                    />
                  </div>
                  <div class="col-3">
                    <label class="control-label">Damage</label>
                    <input
                      class="form-control"
                      type="number"
                      :value="
                        attack.damageBonus +
                        localCharacter.calculated.rangedDamage
                      "
                      disabled
                    />
                  </div>
                  <div class="col-3">
                    <label class="control-label">Dmg +</label>
                    <input
                      class="form-control"
                      type="number"
                      v-model="attack.damageBonus"
                      @change="updateCharacter()"
                    />
                  </div>
                </div>
              </div>

              <div class="col-10">
                <label class="control-label">Description</label>
                <textarea
                  class="form-control"
                  v-model="attack.description"
                  @blur="updateCharacter()"
                ></textarea>
              </div>

              <div class="col-2 d-flex align-items-end justify-content-end">
                <button
                  class="btn btn-outline-secondary"
                  @click="onDeleteAttackClicked(index)"
                >
                  <i class="mdi mdi-delete"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mt-2 mb-4">
      <div class="col-12">
        <label class="control-label">Notes</label>
        <textarea
          class="form-control"
          v-model="localCharacter.notes"
          @blur="updateCharacter()"
        ></textarea>
      </div>
    </div>
    <div class="row mt-2 mb-4">
      <!-- Weapons Card -->
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            Weapons
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Weapon</th>
                  <th>Damage Bonus</th>
                  <th>Accuracy Mod</th>
                  <th>Damage Type</th>
                  <th>Weapon Type</th>
                  <th>Special</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Dagger</td>
                  <td>0</td>
                  <td>30</td>
                  <td>Piercing</td>
                  <td>Melee</td>
                  <td>Can be used as slashing</td>
                </tr>
                <tr>
                  <td>Sword</td>
                  <td>15</td>
                  <td>15</td>
                  <td>Slashing</td>
                  <td>Melee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Battle Hammer</td>
                  <td>30</td>
                  <td>0</td>
                  <td>Bludgeoning</td>
                  <td>Melee</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Spear</td>
                  <td>15</td>
                  <td>0</td>
                  <td>Piercing</td>
                  <td>Melee</td>
                  <td>1 meter of additional range</td>
                </tr>
                <tr>
                  <td>Revolver</td>
                  <td>50</td>
                  <td>15</td>
                  <td>Piercing</td>
                  <td>Ranged</td>
                  <td>6 Shots, -5 acc for each meter of distance.</td>
                </tr>
                <tr>
                  <td>Rifle</td>
                  <td>75</td>
                  <td>30</td>
                  <td>Piercing</td>
                  <td>Ranged</td>
                  <td>1 Shot, -5 acc if target is 10 or more meters away.</td>
                </tr>
                <tr>
                  <td>Gunblade</td>
                  <td></td>
                  <td></td>
                  <td>Composite</td>
                  <td>Melee/Ranged</td>
                  <td>Melee is treated as sword, ranged is treated as rifle.</td>
                </tr>
                <tr>
                  <td>Grenade</td>
                  <td></td>
                  <td></td>
                  <td>Thrown</td>
                  <td></td>
                  <td>Deals 100 piercing damage (-20 for each meter away) to all entities within range.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    
      <!-- Armor Card -->
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            Armor
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Armor</th>
                  <th>Reductions</th>
                  <th>Special</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Leather Armor</td>
                  <td>Bludgeoning 5, Slashing 25, Piercing 10, Force 5</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Chainmail</td>
                  <td>Bludgeoning 5, Slashing 40, Piercing 20, Electric -5</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Full Plate</td>
                  <td>Bludgeoning 20, Slashing 75, Piercing 30, Electric -25, Force 20</td>
                  <td>Reduces movement speed by 1m</td>
                </tr>
                <tr>
                  <td>Shield</td>
                  <td>Bludgeoning 10, Slashing 10, Piercing 10</td>
                  <td>Use move action to raise shield for +20 additional reduction to physical damage sources</td>
                </tr>
                <tr>
                  <td>Affinityweave Cape</td>
                  <td>Choose an element on creation, 25 reduction.</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Helmet</td>
                  <td>Piercing 5, Force 10</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Binding Ring</td>
                  <td></td>
                  <td>Increases binding efficiency of a specific type by 5 base.</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    
      <!-- Equipment Card -->
      <div class="col-6">
        <div class="card">
          <div class="card-header">
            Equipment
          </div>
          <div class="card-body">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th>Equipment</th>
                  <th>Special</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Specialist Ring</td>
                  <td>Choose an element, +10 to cast effectiveness.</td>
                </tr>
                <tr>
                  <td>Bandolier</td>
                  <td>Holds 30 ammo</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
    <div class="row my-4">
      <div class="col-12">
      <div class="card">
        <div class="card-header">
          Rules
        </div>
        <div class="card-body">
          <div class="container">
            <h2>Game Mechanics</h2>
            
            <h3>Resources</h3>
            <ul>
              <li><strong>Focus:</strong> Resource for binding slippage.</li>
              <li><strong>Spirit:</strong> Reduces incoming magic damage before it goes to vitality.</li>
              <li><strong>Stamina:</strong> Can be used to move faster, or exert for more accuracy/damage.</li>
              <li><strong>Temperature:</strong> Core temperature. Can be impacted and spent as a binding resource.</li>
              <li><strong>Vitality:</strong> Health. When this hits 0, the character dies.</li>
              <li><strong>Spell Capacity:</strong> Number of total spells that can be learned.</li>
            </ul>
          
            <h3>Movement</h3>
            <p>Base movement is 5m/turn. For 10 stamina, an additional 1m can be moved.</p>
            <p>Players may either move up to their base movement over the course of a turn, or instead use a ‘movement action’ instead.</p>
            <p>Some movement actions include:</p>
            <ul>
              <li>Reload a weapon</li>
              <li>Take an item from storage</li>
              <li>Pick up an item</li>
            </ul>
          
            <h3>Actions</h3>
            <p>Players have a single action per turn. They may use it to do the following:</p>
            <ul>
              <li>Use a movement action</li>
              <li>Make an attack</li>
              <li>Cast a spell</li>
              <li>Create a binding</li>
              <li>Recover 10 focus if you do not move.</li>
            </ul>
          
            <h4>Attacks</h4>
            <p>Attacks (melee or ranged) run their accuracy against the target’s dodge. If the accuracy is greater, the target is dealt the weapon’s damage, reduced by the relevant damage reduction value. For each 1 stamina a character uses, they can add 1 to an attack’s accuracy or damage.</p>
          
            <h4>Spells</h4>
            <p>Spells are cast by touching a character and dealing their full specialty damage to that character. If the spell is ranged, it reduces the power by 5 for each meter away the target is. If the spell is an AOE, it is reduced by 5 for each meter away the center is, then reduced by half.</p>
            <p>Base power of a spell can be increased by spending Spirit. 1 Spirit spent is 1 additional power to that cast spell.</p>
            <p><b>Spells do not affect their source, so a caster is not impacted by their own spells.</b></p>

            <h4>Bindings</h4>
            <p>Bindings connect things based on the binding type. Depending on the similarity of the objects and binding type, there are 4 binding efficiencies:</p>
            <ul>
              <li><strong>True Binding:</strong> Both items are nearly identical, and/or were originally part of the same object.</li>
              <li><strong>Good Binding:</strong> Both items are very similar, in respect to the binding type.</li>
              <li><strong>Poor Binding:</strong> Both items are not similar.</li>
              <li><strong>No Binding:</strong> Items are so dissimilar that a binding cannot be made.</li>
            </ul>
            <p>Binding two items makes them share the binding properties. For example, breaking a stick in half and giving it a kinetic binding means that picking one up will raise the other. However, at 100% efficiency, the one stick would still feel as heavy as both combined.</p>
            <p>Binding at less than 100% means that energy transferred through it will deal damage proportional to the binder’s focus.</p>
            <p>You can only create a binding if you have enough focus to match its inverse. A 40% binding requires 60 focus to create.</p>
            <p>When a character hits 0 focus, they break all of their bindings.</p>

            <h4>Binding Example</h4>
            <p>Example: Two characters are bound together at 50% efficiency. Character 1 is hit by a 100 power fire spell. First, that character reduces the fire spell by their fire resistance (20). The remaining 80 damage is then cut by the binding efficiency. The binder takes 40 damage to their focus, then both characters each take 40 damage to their spirit, or vitality if they don’t have enough spirit to reduce the damage. If the hit character takes vitality damage, the second character takes at least that much vitality damage.</p>
          </div>
          
        </div>
      </div>
    </div>
    </div>
    
  </div>
</template>

<script>
import CharacterService from "@/services/CharacterService";

export default {
  name: "CharacterEditor",
  props: {
    character: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      // Make a deep copy of the character prop to avoid mutating the original prop directly
      localCharacter: JSON.parse(JSON.stringify(this.character)),
    };
  },
  methods: {
    updateCharacter() {
      // Emit the entire updated character object back to the parent component
      CharacterService.calculateCharacter(this.localCharacter);
      this.$emit("update-character", this.localCharacter);
    },
    onNewAttackClicked() {
      this.localCharacter.attacks.push(CharacterService.newAttack());
      this.updateCharacter();
    },
    onNewSpellClicked() {
      this.localCharacter.spells.push(CharacterService.newSpell());
      this.updateCharacter();
    },
    onDeleteSpellClicked(index) {
      this.localCharacter.spells.splice(index, 1);
      this.updateCharacter();
    },
    onDeleteAttackClicked(index) {
      this.localCharacter.attacks.splice(index, 1);
      this.updateCharacter();
    },
  },
  watch: {
    character: {
      handler(newVal) {
        // Watch for changes in the character prop and update the local copy accordingly
        this.localCharacter = JSON.parse(JSON.stringify(newVal));
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
