// src/services/CharacterService.js
// Please only address things marked TODO
// Only provide changed code. Do not provide code that remains the same.

export default {
  newCharacter(name) {
    return {
      id: Date.now(),
      name: name,
      core: {
        affinity: 10,
        agility: 10,
        awareness: 10,
        intelligence: 10,
        precision: 10,
        strength: 10,
        willpower: 10,
      },
      calculated: {
        // Resources
        focus: 0,
        focusBonus: 0,
        focusMax: 0,
        spirit: 0,
        spiritBonus: 0,
        spiritMax: 0,
        stamina: 0,
        staminaBonus: 0,
        staminaMax: 0,
        temperature: 99,
        temperatureMax: 99,
        vitality: 0,
        vitalityBonus: 0,
        vitalityMax: 0,
        spellCapacity: 0,
        spellCapacityBonus: 0,
        spellCapacityMax: 0,
        gold: 0,

        //Binding Base
        baseTrueBindingEfficiency: 0,
        baseGoodBindingEfficiency: 0,
        basePoorBindingEfficiency: 0,

        //Specific binding
        biochemicalBindingTrained: false,
        biochemicalTrueBindingEfficiency: 0,
        biochemicalGoodBindingEfficiency: 0,
        biochemicalPoorBindingEfficiency: 0,
        biochemicalBindingBonus: 0,

        electromagneticBindingTrained: false,
        electromagneticTrueBindingEfficiency: 0,
        electromagneticGoodBindingEfficiency: 0,
        electromagneticPoorBindingEfficiency: 0,
        electromagneticBindingBonus: 0,

        kineticBindingTrained: false,
        kineticTrueBindingEfficiency: 0,
        kineticGoodBindingEfficiency: 0,
        kineticPoorBindingEfficiency: 0,
        kineticBindingBonus: 0,

        photonicBindingTrained: false,
        photonicTrueBindingEfficiency: 0,
        photonicGoodBindingEfficiency: 0,
        photonicPoorBindingEfficiency: 0,
        photonicBindingBonus: 0,

        sonicBindingTrained: false,
        sonicTrueBindingEfficiency: 0,
        sonicGoodBindingEfficiency: 0,
        sonicPoorBindingEfficiency: 0,
        sonicBindingBonus: 0,

        thermalBindingTrained: false,
        thermalTrueBindingEfficiency: 0,
        thermalGoodBindingEfficiency: 0,
        thermalPoorBindingEfficiency: 0,
        thermalBindingBonus: 0,

        //Spell Affinities
        baseAffinity: 0,
        biochemicalAffinity: 0,
        electromagneticAffinity: 0,
        kineticAffinity: 0,
        photonicAffinity: 0,
        sonicAffinity: 0,
        thermalAffinity: 0,

        bonusBiochemicalAffinity: 0,
        bonusElectromagneticAffinity: 0,
        bonusKineticAffinity: 0,
        bonusPhotonicAffinity: 0,
        bonusSonicAffinity: 0,
        bonusThermalAffinity: 0,

        //Spell Specialties
        healingSpecialty: 0,
        poisonSpecialty: 0,
        acidicSpecialty: 0,
        waterSpecialty: 0,
        electricSpecialty: 0,
        magneticSpecialty: 0,
        forceSpecialty: 0,
        gravitationalSpecialty: 0,
        lightSpecialty: 0,
        darkSpecialty: 0,
        communicationSpecialty: 0,
        vibrationSpecialty: 0,
        fireSpecialty: 0,
        iceSpecialty: 0,

        bonusHealingSpecialty: 0,
        bonusPoisonSpecialty: 0,
        bonusAcidicSpecialty: 0,
        bonusWaterSpecialty: 0,
        bonusElectricSpecialty: 0,
        bonusMagneticSpecialty: 0,
        bonusForceSpecialty: 0,
        bonusGravitationalSpecialty: 0,
        bonusLightSpecialty: 0,
        bonusDarkSpecialty: 0,
        bonusCommunicationSpecialty: 0,
        bonusVibrationSpecialty: 0,
        bonusFireSpecialty: 0,
        bonusIceSpecialty: 0,

        //Spell Training
        healingTraining: false,
        poisonTraining: false,
        acidicTraining: false,
        waterTraining: false,
        electricTraining: false,
        magneticTraining: false,
        forceTraining: false,
        gravitationalTraining: false,
        lightTraining: false,
        darkTraining: false,
        communicationTraining: false,
        vibrationTraining: false,
        fireTraining: false,
        iceTraining: false,

        //Attack Base
        meleeAccuracy: 0,
        meleeDodge: 0,
        meleeDamage: 0,
        rangedAccuracy: 0,
        rangedDodge: 0,
        rangedDamage: 0,

        bonusMeleeAccuracy: 0,
        bonusMeleeDodge: 0,
        bonusRangedAccuracy: 0,
        bonusRangedDodge: 0,

        //Growth
        currentExp: 0,
        expRate: 1,
        expBarDisplay: 50,
        level: 1,
        usedPoints: 0,
        maxPoints: 1,
        canIncreaseCost: true,
        canDecreaseCost: true,

        //Misc Stats
        stealth: 0,
        perception: 0,
        BonusStealth: 0,
        BonusPerception: 0,

        // Reduction Stats
        piercingDamageReduction: 0,
        slashingDamageReduction: 0,
        bludgeoningDamageReduction: 0,
        healingDamageReduction: 0,
        poisonDamageReduction: 0,
        acidicDamageReduction: 0,
        waterDamageReduction: 0,
        electricDamageReduction: 0,
        magneticDamageReduction: 0,
        forceDamageReduction: 0,
        gravitationalDamageReduction: 0,
        lightDamageReduction: 0,
        darkDamageReduction: 0,
        communicationDamageReduction: 0,
        vibrationDamageReduction: 0,
        fireDamageReduction: 0,
        iceDamageReduction: 0,


        bonusPiercingDamageReduction: 0,
        bonusSlashingDamageReduction: 0,
        bonusBludgeoningDamageReduction: 0,
        bonusHealingDamageReduction: 0,
        bonusPoisonDamageReduction: 0,
        bonusAcidicDamageReduction: 0,
        bonusWaterDamageReduction: 0,
        bonusElectricDamageReduction: 0,
        bonusMagneticDamageReduction: 0,
        bonusForceDamageReduction: 0,
        bonusGravitationalDamageReduction: 0,
        bonusLightDamageReduction: 0,
        bonusDarkDamageReduction: 0,
        bonusCommunicationDamageReduction: 0,
        bonusVibrationDamageReduction: 0,
        bonusFireDamageReduction: 0,
        bonusIceDamageReduction: 0,
      },
      attacks: [],
      spells: [],
      armor: [],
      buffs: [],
      notes: '',
    }
  },

  calculateCharacter(char) {
    if (!char) {
      console.log("Character not initializing, skipping calculation");
    }

    for (const key in char.core) {
      if (char.core[key] <= -1) {
        char.core[key] = 0;
      }
    }
  
    // Loop through char.calculated properties
    for (const key in char.calculated) {
      if (char.calculated[key] <= -1) {
        char.calculated[key] = 0;
      }
    }

    if(char.core.affinity > 50){
      char.core.affinity = 50;
    }
    if(char.core.agility > 50){
      char.core.agility = 50;
    }
    if(char.core.awareness > 50){
      char.core.awareness = 50;
    }
    if(char.core.intelligence > 50){
      char.core.intelligence = 50;
    }
    if(char.core.precision > 50){
      char.core.precision = 50;
    }
    if(char.core.strength > 50){
      char.core.strength = 50;
    }
    if(char.core.willpower > 50){
      char.core.willpower = 50;
    }

    //Binding Base
    char.calculated.baseTrueBindingEfficiency = 30 + char.core.intelligence;
    char.calculated.baseGoodBindingEfficiency = 15 + char.core.intelligence;
    char.calculated.basePoorBindingEfficiency = 0 + char.core.intelligence;


    //Resources
    char.calculated.focusMax = 0 + (char.core.awareness * 2) + char.core.intelligence + char.calculated.focusBonus;
    char.calculated.spiritMax = 0 + (char.core.willpower) + (char.core.intelligence) + (char.core.affinity) + char.calculated.spiritBonus;
    char.calculated.staminaMax = 0 + char.core.strength + (char.core.willpower * 2) + char.calculated.staminaBonus;
    char.calculated.vitalityMax = 0 + (char.core.strength * 5) + (char.core.willpower * 2) + (char.calculated.vitalityBonus * 2);
    char.calculated.spellCapacityMax = 0 + Number((char.core.intelligence /5).toFixed(0)) + char.calculated.spellCapacityBonus;


    // Specific Binding
    char.calculated.biochemicalTrueBindingEfficiency = char.calculated.biochemicalBindingTrained ? char.calculated.baseTrueBindingEfficiency + (char.calculated.biochemicalBindingBonus * 1) : 0;
    char.calculated.biochemicalGoodBindingEfficiency = char.calculated.biochemicalBindingTrained ? char.calculated.baseGoodBindingEfficiency + (char.calculated.biochemicalBindingBonus * 1) : 0;
    char.calculated.biochemicalPoorBindingEfficiency = char.calculated.biochemicalBindingTrained ? char.calculated.basePoorBindingEfficiency + (char.calculated.biochemicalBindingBonus * 1) : 0;

    char.calculated.electromagneticTrueBindingEfficiency = char.calculated.electromagneticBindingTrained ? char.calculated.baseTrueBindingEfficiency + (char.calculated.electromagneticBindingBonus * 1) : 0;
    char.calculated.electromagneticGoodBindingEfficiency = char.calculated.electromagneticBindingTrained ? char.calculated.baseGoodBindingEfficiency + (char.calculated.electromagneticBindingBonus * 1) : 0;
    char.calculated.electromagneticPoorBindingEfficiency = char.calculated.electromagneticBindingTrained ? char.calculated.basePoorBindingEfficiency + (char.calculated.electromagneticBindingBonus * 1) : 0;

    char.calculated.kineticTrueBindingEfficiency = char.calculated.kineticBindingTrained ? char.calculated.baseTrueBindingEfficiency + (char.calculated.kineticBindingBonus * 1) : 0;
    char.calculated.kineticGoodBindingEfficiency = char.calculated.kineticBindingTrained ? char.calculated.baseGoodBindingEfficiency + (char.calculated.kineticBindingBonus * 1) : 0;
    char.calculated.kineticPoorBindingEfficiency = char.calculated.kineticBindingTrained ? char.calculated.basePoorBindingEfficiency + (char.calculated.kineticBindingBonus * 1) : 0;

    char.calculated.photonicTrueBindingEfficiency = char.calculated.photonicBindingTrained ? char.calculated.baseTrueBindingEfficiency + (char.calculated.photonicBindingBonus * 1) : 0;
    char.calculated.photonicGoodBindingEfficiency = char.calculated.photonicBindingTrained ? char.calculated.baseGoodBindingEfficiency + (char.calculated.photonicBindingBonus * 1) : 0;
    char.calculated.photonicPoorBindingEfficiency = char.calculated.photonicBindingTrained ? char.calculated.basePoorBindingEfficiency + (char.calculated.photonicBindingBonus * 1) : 0;

    char.calculated.sonicTrueBindingEfficiency = char.calculated.sonicBindingTrained ? char.calculated.baseTrueBindingEfficiency + (char.calculated.sonicBindingBonus * 1) : 0;
    char.calculated.sonicGoodBindingEfficiency = char.calculated.sonicBindingTrained ? char.calculated.baseGoodBindingEfficiency + (char.calculated.sonicBindingBonus * 1) : 0;
    char.calculated.sonicPoorBindingEfficiency = char.calculated.sonicBindingTrained ? char.calculated.basePoorBindingEfficiency + (char.calculated.sonicBindingBonus * 1) : 0;

    char.calculated.thermalTrueBindingEfficiency = char.calculated.thermalBindingTrained ? char.calculated.baseTrueBindingEfficiency + (char.calculated.thermalBindingBonus * 1) : 0;
    char.calculated.thermalGoodBindingEfficiency = char.calculated.thermalBindingTrained ? char.calculated.baseGoodBindingEfficiency + (char.calculated.thermalBindingBonus * 1) : 0;
    char.calculated.thermalPoorBindingEfficiency = char.calculated.thermalBindingTrained ? char.calculated.basePoorBindingEfficiency + (char.calculated.thermalBindingBonus * 1) : 0;


    //Spell Affinities
    char.calculated.baseAffinity = char.core.affinity;
    char.calculated.biochemicalAffinity = char.calculated.baseAffinity + char.calculated.bonusBiochemicalAffinity;
    char.calculated.electromagneticAffinity = char.calculated.baseAffinity + char.calculated.bonusElectromagneticAffinity;
    char.calculated.kineticAffinity = char.calculated.baseAffinity + char.calculated.bonusKineticAffinity;
    char.calculated.photonicAffinity = char.calculated.baseAffinity + char.calculated.bonusPhotonicAffinity;
    char.calculated.sonicAffinity = char.calculated.baseAffinity + char.calculated.bonusSonicAffinity;
    char.calculated.thermalAffinity = char.calculated.baseAffinity + char.calculated.bonusThermalAffinity;

    //Spell Specialties
    char.calculated.healingSpecialty = char.calculated.biochemicalAffinity;
    char.calculated.poisonSpecialty =char.calculated.biochemicalAffinity;
    char.calculated.acidicSpecialty =char.calculated.biochemicalAffinity;
    char.calculated.waterSpecialty =  char.calculated.biochemicalAffinity;
    char.calculated.electricSpecialty =  char.calculated.electromagneticAffinity;
    char.calculated.magneticSpecialty = char.calculated.electromagneticAffinity;
    char.calculated.forceSpecialty =  char.calculated.kineticAffinity;
    char.calculated.gravitationalSpecialty =  char.calculated.kineticAffinity;
    char.calculated.lightSpecialty =  char.calculated.photonicAffinity;
    char.calculated.darkSpecialty =  char.calculated.photonicAffinity;
    char.calculated.communicationSpecialty =  char.calculated.sonicAffinity;
    char.calculated.vibrationSpecialty =  char.calculated.sonicAffinity;
    char.calculated.fireSpecialty =  char.calculated.thermalAffinity;
    char.calculated.iceSpecialty =  char.calculated.thermalAffinity;

// Healing Specialty
if(char.calculated.healingTraining) {
  char.calculated.healingSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusHealingSpecialty; i++) {
  if (char.calculated.healingSpecialty <= 50) {
    char.calculated.healingSpecialty += 1;
  } else if (char.calculated.healingSpecialty <= 75) {
    char.calculated.healingSpecialty += 0.5;
  } else if (char.calculated.healingSpecialty <= 100) {
    char.calculated.healingSpecialty += 0.25;
  } else {
    char.calculated.healingSpecialty += 0.2;
  }
}
char.calculated.healingSpecialty = Number(char.calculated.healingSpecialty.toFixed(0))

// Poison Specialty
if(char.calculated.poisonTraining) {
  char.calculated.poisonSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusPoisonSpecialty; i++) {
  if (char.calculated.poisonSpecialty <= 50) {
    char.calculated.poisonSpecialty += 1;
  } else if (char.calculated.poisonSpecialty <= 75) {
    char.calculated.poisonSpecialty += 0.5;
  } else if (char.calculated.poisonSpecialty <= 100) {
    char.calculated.poisonSpecialty += 0.25;
  } else {
    char.calculated.poisonSpecialty += 0.2;
  }
}
char.calculated.poisonSpecialty = Number(char.calculated.poisonSpecialty.toFixed(0))

// Acidic Specialty
if(char.calculated.acidicTraining) {
  char.calculated.acidicSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusAcidicSpecialty; i++) {
  if (char.calculated.acidicSpecialty <= 50) {
    char.calculated.acidicSpecialty += 1;
  } else if (char.calculated.acidicSpecialty <= 75) {
    char.calculated.acidicSpecialty += 0.5;
  } else if (char.calculated.acidicSpecialty <= 100) {
    char.calculated.acidicSpecialty += 0.25;
  } else {
    char.calculated.acidicSpecialty += 0.2;
  }
}
char.calculated.acidicSpecialty = Number(char.calculated.acidicSpecialty.toFixed(0))

// Water Specialty
if(char.calculated.waterTraining) {
  char.calculated.waterSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusWaterSpecialty; i++) {
  if (char.calculated.waterSpecialty <= 50) {
    char.calculated.waterSpecialty += 1;
  } else if (char.calculated.waterSpecialty <= 75) {
    char.calculated.waterSpecialty += 0.5;
  } else if (char.calculated.waterSpecialty <= 100) {
    char.calculated.waterSpecialty += 0.25;
  } else {
    char.calculated.waterSpecialty += 0.2;
  }
}
char.calculated.waterSpecialty = Number(char.calculated.waterSpecialty.toFixed(0))

// Electric Specialty
if(char.calculated.electricTraining) {
  char.calculated.electricSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusElectricSpecialty; i++) {
  if (char.calculated.electricSpecialty <= 50) {
    char.calculated.electricSpecialty += 1;
  } else if (char.calculated.electricSpecialty <= 75) {
    char.calculated.electricSpecialty += 0.5;
  } else if (char.calculated.electricSpecialty <= 100) {
    char.calculated.electricSpecialty += 0.25;
  } else {
    char.calculated.electricSpecialty += 0.2;
  }
}
char.calculated.electricSpecialty = Number(char.calculated.electricSpecialty.toFixed(0))

// Magnetic Specialty
if(char.calculated.magneticTraining) {
  char.calculated.magneticSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusMagneticSpecialty; i++) {
  if (char.calculated.magneticSpecialty <= 50) {
    char.calculated.magneticSpecialty += 1;
  } else if (char.calculated.magneticSpecialty <= 75) {
    char.calculated.magneticSpecialty += 0.5;
  } else if (char.calculated.magneticSpecialty <= 100) {
    char.calculated.magneticSpecialty += 0.25;
  } else {
    char.calculated.magneticSpecialty += 0.2;
  }
}
char.calculated.magneticSpecialty = Number(char.calculated.magneticSpecialty.toFixed(0))

// Force Specialty
if(char.calculated.forceTraining) {
  char.calculated.forceSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusForceSpecialty; i++) {
  if (char.calculated.forceSpecialty <= 50) {
    char.calculated.forceSpecialty += 1;
  } else if (char.calculated.forceSpecialty <= 75) {
    char.calculated.forceSpecialty += 0.5;
  } else if (char.calculated.forceSpecialty <= 100) {
    char.calculated.forceSpecialty += 0.25;
  } else {
    char.calculated.forceSpecialty += 0.2;
  }
}
char.calculated.forceSpecialty = Number(char.calculated.forceSpecialty.toFixed(0))

// Gravitational Specialty
if(char.calculated.gravitationalTraining) {
  char.calculated.gravitationalSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusGravitationalSpecialty; i++) {
  if (char.calculated.gravitationalSpecialty <= 50) {
    char.calculated.gravitationalSpecialty += 1;
  } else if (char.calculated.gravitationalSpecialty <= 75) {
    char.calculated.gravitationalSpecialty += 0.5;
  } else if (char.calculated.gravitationalSpecialty <= 100) {
    char.calculated.gravitationalSpecialty += 0.25;
  } else {
    char.calculated.gravitationalSpecialty += 0.2;
  }
}
char.calculated.gravitationalSpecialty = Number(char.calculated.gravitationalSpecialty.toFixed(0))

// Light Specialty
if(char.calculated.lightTraining) {
  char.calculated.lightSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusLightSpecialty; i++) {
  if (char.calculated.lightSpecialty <= 50) {
    char.calculated.lightSpecialty += 1;
  } else if (char.calculated.lightSpecialty <= 75) {
    char.calculated.lightSpecialty += 0.5;
  } else if (char.calculated.lightSpecialty <= 100) {
    char.calculated.lightSpecialty += 0.25;
  } else {
    char.calculated.lightSpecialty += 0.2;
  }
}
char.calculated.lightSpecialty = Number(char.calculated.lightSpecialty.toFixed(0))

// Dark Specialty
if(char.calculated.darkTraining) {
  char.calculated.darkSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusDarkSpecialty; i++) {
  if (char.calculated.darkSpecialty <= 50) {
    char.calculated.darkSpecialty += 1;
  } else if (char.calculated.darkSpecialty <= 75) {
    char.calculated.darkSpecialty += 0.5;
  } else if (char.calculated.darkSpecialty <= 100) {
    char.calculated.darkSpecialty += 0.25;
  } else {
    char.calculated.darkSpecialty += 0.2;
  }
}
char.calculated.darkSpecialty = Number(char.calculated.darkSpecialty.toFixed(0))

// Communication Specialty
if(char.calculated.communicationTraining) {
  char.calculated.communicationSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusCommunicationSpecialty; i++) {
  if (char.calculated.communicationSpecialty <= 50) {
    char.calculated.communicationSpecialty += 1;
  } else if (char.calculated.communicationSpecialty <= 75) {
    char.calculated.communicationSpecialty += 0.5;
  } else if (char.calculated.communicationSpecialty <= 100) {
    char.calculated.communicationSpecialty += 0.25;
  } else {
    char.calculated.communicationSpecialty += 0.2;
  }
}
char.calculated.communicationSpecialty = Number(char.calculated.communicationSpecialty.toFixed(0))

// Vibration Specialty
if(char.calculated.vibrationTraining) {
  char.calculated.vibrationSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusVibrationSpecialty; i++) {
  if (char.calculated.vibrationSpecialty <= 50) {
    char.calculated.vibrationSpecialty += 1;
  } else if (char.calculated.vibrationSpecialty <= 75) {
    char.calculated.vibrationSpecialty += 0.5;
  } else if (char.calculated.vibrationSpecialty <= 100) {
    char.calculated.vibrationSpecialty += 0.25;
  } else {
    char.calculated.vibrationSpecialty += 0.2;
  }
}
char.calculated.vibrationSpecialty = Number(char.calculated.vibrationSpecialty.toFixed(0))

// Fire Specialty
if(char.calculated.fireTraining) {
  char.calculated.fireSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusFireSpecialty; i++) {
  if (char.calculated.fireSpecialty <= 50) {
    char.calculated.fireSpecialty += 1;
  } else if (char.calculated.fireSpecialty <= 75) {
    char.calculated.fireSpecialty += 0.5;
  } else if (char.calculated.fireSpecialty <= 100) {
    char.calculated.fireSpecialty += 0.25;
  } else {
    char.calculated.fireSpecialty += 0.2;
  }
}
char.calculated.fireSpecialty = Number(char.calculated.fireSpecialty.toFixed(0))

// Ice Specialty
if(char.calculated.iceTraining) {
  char.calculated.iceSpecialty += 25;
}

for (let i = 0; i < char.calculated.bonusIceSpecialty; i++) {
  if (char.calculated.iceSpecialty <= 50) {
    char.calculated.iceSpecialty += 1;
  } else if (char.calculated.iceSpecialty <= 75) {
    char.calculated.iceSpecialty += 0.5;
  } else if (char.calculated.iceSpecialty <= 100) {
    char.calculated.iceSpecialty += 0.25;
  } else {
    char.calculated.iceSpecialty += 0.2;
  }
}
char.calculated.iceSpecialty = Number(char.calculated.iceSpecialty.toFixed(0))





    //Attack Base
    char.calculated.meleeAccuracy = (char.core.precision * 2) + (char.core.strength) + char.calculated.bonusMeleeAccuracy;
    char.calculated.meleeDodge = (char.core.agility * 2) + char.core.awareness + char.calculated.bonusMeleeDodge;
    char.calculated.rangedAccuracy = (char.core.precision * 2) + char.core.awareness + char.calculated.bonusRangedAccuracy;
    char.calculated.rangedDodge = (char.core.awareness * 2) + char.core.agility + char.calculated.bonusRangedDodge;
    char.calculated.meleeDamage = (char.core.strength * 3);
    char.calculated.rangedDamage = 0;

    //Misc Stats
    char.calculated.stealth = char.core.agility + char.core.intelligence + char.core.precision + char.calculated.BonusStealth;
    char.calculated.perception = (char.core.awareness * 2) + char.core.intelligence + char.calculated.BonusPerception;

    // Reduction Stats
    char.calculated.piercingDamageReduction = char.calculated.bonusPiercingDamageReduction;
    char.calculated.slashingDamageReduction = char.calculated.bonusSlashingDamageReduction;
    char.calculated.bludgeoningDamageReduction = char.calculated.bonusBludgeoningDamageReduction;

    char.calculated.healingDamageReduction = char.calculated.bonusHealingDamageReduction + char.core.willpower - (char.calculated.healingSpecialty + char.core.affinity);
    if(char.calculated.healingDamageReduction < 0){
      char.calculated.healingDamageReduction = 0;
    }
    char.calculated.poisonDamageReduction = char.calculated.bonusPoisonDamageReduction + char.core.willpower + char.calculated.poisonSpecialty - char.core.affinity;
    char.calculated.acidicDamageReduction = char.calculated.bonusAcidicDamageReduction + char.core.willpower + char.calculated.acidicSpecialty - char.core.affinity;
    char.calculated.waterDamageReduction = char.calculated.bonusWaterDamageReduction + char.core.willpower + char.calculated.waterSpecialty - char.core.affinity;
    char.calculated.electricDamageReduction = char.calculated.bonusElectricDamageReduction + char.core.willpower + char.calculated.electricSpecialty - char.core.affinity;
    char.calculated.magneticDamageReduction = char.calculated.bonusMagneticDamageReduction + char.core.willpower + char.calculated.magneticSpecialty - char.core.affinity;
    char.calculated.forceDamageReduction = char.calculated.bonusForceDamageReduction + char.core.willpower + char.calculated.forceSpecialty - char.core.affinity;
    char.calculated.gravitationalDamageReduction = char.calculated.bonusGravitationalDamageReduction + char.core.willpower + char.calculated.gravitationalSpecialty - char.core.affinity;
    char.calculated.lightDamageReduction = char.calculated.bonusLightDamageReduction + char.core.willpower + char.calculated.lightSpecialty - char.core.affinity;
    char.calculated.darkDamageReduction = char.calculated.bonusDarkDamageReduction + char.core.willpower + char.calculated.darkSpecialty - char.core.affinity;
    char.calculated.communicationDamageReduction = char.calculated.bonusCommunicationDamageReduction + char.core.willpower + char.calculated.communicationSpecialty - char.core.affinity;
    char.calculated.vibrationDamageReduction = char.calculated.bonusVibrationDamageReduction + char.core.willpower + char.calculated.vibrationSpecialty - char.core.affinity;
    char.calculated.fireDamageReduction = char.calculated.bonusFireDamageReduction + char.core.willpower + char.calculated.fireSpecialty - char.core.affinity;
    char.calculated.iceDamageReduction = char.calculated.bonusIceDamageReduction + char.core.willpower + char.calculated.iceSpecialty - char.core.affinity;

    if(char.calculated.biochemicalTrueBindingEfficiency> 99)char.calculated.biochemicalTrueBindingEfficiency = 99; 
    if(char.calculated.biochemicalGoodBindingEfficiency> 99)char.calculated.biochemicalGoodBindingEfficiency = 99; 
    if(char.calculated.biochemicalPoorBindingEfficiency> 99)char.calculated.biochemicalPoorBindingEfficiency = 99; 
    if(char.calculated.electromagneticTrueBindingEfficiency> 99)char.calculated.electromagneticTrueBindingEfficiency = 99;
    if(char.calculated.electromagneticGoodBindingEfficiency> 99)char.calculated.electromagneticGoodBindingEfficiency = 99;
    if(char.calculated.electromagneticPoorBindingEfficiency> 99)char.calculated.electromagneticPoorBindingEfficiency = 99;
    if(char.calculated.kineticTrueBindingEfficiency> 99)char.calculated.kineticTrueBindingEfficiency = 99;
    if(char.calculated.kineticGoodBindingEfficiency> 99)char.calculated.kineticGoodBindingEfficiency = 99;
    if(char.calculated.kineticPoorBindingEfficiency> 99)char.calculated.kineticPoorBindingEfficiency = 99;
    if(char.calculated.photonicTrueBindingEfficiency> 99)char.calculated.photonicTrueBindingEfficiency = 99;
    if(char.calculated.photonicGoodBindingEfficiency> 99)char.calculated.photonicGoodBindingEfficiency = 99;
    if(char.calculated.photonicPoorBindingEfficiency> 99)char.calculated.photonicPoorBindingEfficiency = 99;
    if(char.calculated.sonicTrueBindingEfficiency> 99)char.calculated.sonicTrueBindingEfficiency = 99;
    if(char.calculated.sonicGoodBindingEfficiency> 99)char.calculated.sonicGoodBindingEfficiency = 99;
    if(char.calculated.sonicPoorBindingEfficiency> 99)char.calculated.sonicPoorBindingEfficiency = 99;
    if(char.calculated.thermalTrueBindingEfficiency> 99)char.calculated.thermalTrueBindingEfficiency = 99;
    if(char.calculated.thermalGoodBindingEfficiency> 99)char.calculated.thermalGoodBindingEfficiency = 99;
    if(char.calculated.thermalPoorBindingEfficiency> 99)char.calculated.thermalPoorBindingEfficiency = 99;
    



    char.calculated.usedPoints = 0;
    char.calculated.usedPoints += (char.core.affinity + char.core.agility + char.core.awareness + char.core.intelligence + char.core.precision + char.core.strength + char.core.willpower) * 6;
    char.calculated.usedPoints += (char.calculated.focusBonus + char.calculated.spiritBonus + char.calculated.staminaBonus + char.calculated.vitalityBonus);
    char.calculated.usedPoints += char.calculated.spellCapacityBonus * 8;
    char.calculated.usedPoints += (char.calculated.biochemicalBindingBonus + char.calculated.electromagneticBindingBonus + char.calculated.kineticBindingBonus + char.calculated.photonicBindingBonus + char.calculated.sonicBindingBonus + char.calculated.thermalBindingBonus) * 2;
    
    char.calculated.usedPoints += char.calculated.bonusBiochemicalAffinity * 3
    char.calculated.usedPoints += char.calculated.bonusElectromagneticAffinity * 3
    char.calculated.usedPoints += char.calculated.bonusKineticAffinity * 3
    char.calculated.usedPoints += char.calculated.bonusPhotonicAffinity * 3
    char.calculated.usedPoints += char.calculated.bonusSonicAffinity * 3
    char.calculated.usedPoints += char.calculated.bonusThermalAffinity * 3

    char.calculated.usedPoints += char.calculated.bonusHealingSpecialty;
    char.calculated.usedPoints += char.calculated.bonusPoisonSpecialty;
    char.calculated.usedPoints += char.calculated.bonusAcidicSpecialty;
    char.calculated.usedPoints += char.calculated.bonusWaterSpecialty;
    char.calculated.usedPoints += char.calculated.bonusElectricSpecialty;
    char.calculated.usedPoints += char.calculated.bonusMagneticSpecialty;
    char.calculated.usedPoints += char.calculated.bonusForceSpecialty;
    char.calculated.usedPoints += char.calculated.bonusGravitationalSpecialty;
    char.calculated.usedPoints += char.calculated.bonusLightSpecialty;
    char.calculated.usedPoints += char.calculated.bonusDarkSpecialty;
    char.calculated.usedPoints += char.calculated.bonusCommunicationSpecialty;
    char.calculated.usedPoints += char.calculated.bonusVibrationSpecialty;
    char.calculated.usedPoints += char.calculated.bonusFireSpecialty;
    char.calculated.usedPoints += char.calculated.bonusIceSpecialty;

    char.calculated.usedPoints += char.calculated.healingTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.poisonTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.acidicTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.waterTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.electricTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.magneticTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.forceTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.gravitationalTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.lightTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.darkTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.communicationTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.vibrationTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.fireTraining ? 4: 0;
    char.calculated.usedPoints += char.calculated.iceTraining ? 4: 0;

    char.calculated.usedPoints += char.calculated.biochemicalBindingTrained ? 6: 0;
    char.calculated.usedPoints += char.calculated.electromagneticBindingTrained ? 6: 0;
    char.calculated.usedPoints += char.calculated.kineticBindingTrained ? 6: 0;
    char.calculated.usedPoints += char.calculated.photonicBindingTrained ? 6: 0;
    char.calculated.usedPoints += char.calculated.sonicBindingTrained ? 6: 0;
    char.calculated.usedPoints += char.calculated.thermalBindingTrained ? 6: 0;

    char.calculated.usedPoints += char.calculated.bonusMeleeAccuracy * 3;
    char.calculated.usedPoints += char.calculated.bonusMeleeDodge * 3;
    char.calculated.usedPoints += char.calculated.bonusRangedAccuracy * 3;
    char.calculated.usedPoints += char.calculated.bonusRangedDodge * 3;
    char.calculated.usedPoints += char.calculated.BonusStealth * 3;
    char.calculated.usedPoints += char.calculated.BonusPerception * 3;
    
    var pointBaseValue = 460 + (char.calculated.level * 50);
    var pointVarianceWarn = 1.1;
    var pointVarianceWarnMax = pointVarianceWarn * pointBaseValue;
    var pointVarianceWarnMin = (1/pointVarianceWarn) * pointBaseValue;
    var pointVarianceError = 1.2;
    
    char.calculated.maxPoints = pointBaseValue * pointVarianceError;
    
    char.calculated.expRate = (pointBaseValue / char.calculated.usedPoints).toFixed(1);
    char.calculated.canIncreaseCost = true;
    char.calculated.canDecreaseCost = true;
    char.calculated.expBarColor = 'bg-success';
    
    // Update for warning threshold
    if (char.calculated.usedPoints >= pointVarianceWarnMax ) {
      char.calculated.expBarColor = 'bg-warning';
    }

    if(char.calculated.usedPoints <= pointVarianceWarnMin){
      char.calculated.expBarColor = 'bg-primary';
    }
    
    // Update for error thresholds
    if (char.calculated.expRate >= pointVarianceError) {
      // char.calculated.canDecreaseCost = false;
      char.calculated.expBarColor = 'bg-danger';
    }
    if (char.calculated.expRate <= (1 / pointVarianceError).toFixed(1)) {
      // char.calculated.canIncreaseCost = false;
      char.calculated.expBarColor = 'bg-danger';
    }
    

  var pointsMin = pointBaseValue * (1/ pointVarianceError);
  var pointsMax = pointBaseValue * pointVarianceError;
  
  var increment = 100 / (pointsMax - pointsMin);
  
  char.calculated.expBarDisplay = ((char.calculated.usedPoints - pointsMin) * increment).toFixed(1);
  
  if (char.calculated.expBarDisplay < 0) {
    char.calculated.expBarDisplay = 0;
  } else if (char.calculated.expBarDisplay > 100) {
    char.calculated.expBarDisplay = 100;
  }

  },

  newSpell() {
    var spell = {
      id: Date.now(),
      name: null,
      description: null,
      type: null,
      style: null,
    };

    return spell;
  },

  newAttack() {
    var attack = {
      id: Date.now(),
      name: null,
      description: null,
      damageBonus: 0,
      accuracyBonus: 0,
      type: null,
    };

    return attack;
  },

  newArmor() {
    var armor = {
      id: Date.now(),
      name: null,
      description: null,
      isWorn: true,
      piercingDamageReduction: 0,
      slashingDamageReduction: 0,
      bludgeoningDamageReduction: 0,
      healingDamageReduction: 0,
      poisonDamageReduction: 0,
      acidicDamageReduction: 0,
      waterDamageReduction: 0,
      electricDamageReduction: 0,
      magneticDamageReduction: 0,
      forceDamageReduction: 0,
      gravitationalDamageReduction: 0,
      lightDamageReduction: 0,
      darkDamageReduction: 0,
      communicationDamageReduction: 0,
      vibrationDamageReduction: 0,
      fireDamageReduction: 0,
      iceDamageReduction: 0,
    };

    return armor;
  },

  // Save all characters passed in to browser local storage.
  saveCharacters(characters) {
    console.log("saveCharacters called with:", characters);
    try {
      localStorage.setItem("characters", JSON.stringify(characters));
      console.log("Characters successfully saved to local storage.");
    } catch (error) {
      console.error("Failed to save characters:", error);
    }
  },

  // Load characters from browser local storage.
  loadCharacters() {
    console.log("loadCharacters called");
    try {
      const characters = JSON.parse(localStorage.getItem("characters") || "[]");
      console.log("Characters loaded:", characters);
      return characters;
    } catch (error) {
      console.error("Failed to load characters:", error);
      return [];
    }
  },

  // Download character passed in as a JSON file with name 'characterCharacterNameYYYYMMDDmmsss'.
  downloadCharacter(character) {
    console.log("downloadCharacter called with:", character);
    try {
      const fileName = `character${character.name}${new Date()
        .toISOString()
        .replace(/[-:.TZ]/g, "")}.json`;
      const file = new Blob([JSON.stringify(character)], {
        type: "application/json",
      });

      // Create a link element, set the download attribute, and trigger the download
      const a = document.createElement("a");
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      console.log("Character successfully downloaded as:", fileName);
    } catch (error) {
      console.error("Failed to download character:", error);
    }
  },

  // This is called from a file upload and pushes characterData to characters.
  uploadCharacter(characterData, characters) {
    console.log("uploadCharacter called with:", characterData);
    try {
      characters.push(characterData);
      console.log("Character successfully uploaded and added to the list.");
    } catch (error) {
      console.error("Failed to upload character:", error);
    }
  },
};
